import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { logEventGA4, migrateReduxSessionStorageFilterValue, sessionStorageMF, setFilterValue, useLocationStore } from '@medifind/zustand';

const createState = (selected) => {
  return { name: 'genericRadius', type: 'singleSelect', selected };
};

const initialState = createState({ value: 25, fidelity: 3 });

const storeName = 'genericRadius';
export const useGenericRadiusFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => {
          const { fidelity } = useLocationStore.getState();
          return set({ ...getInitialStateByLocationFidelity(fidelity) }, false, { type: 'reset' });
        },
        getInitialState: () => {
          const { fidelity } = useLocationStore.getState();
          return getInitialStateByLocationFidelity(fidelity);
        },
        setFilterValue: (v) => updateGenericRadiusFilter(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'genericRadius' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const updateGenericRadiusFilter = (option) => {
  if (option && process.env.NX_APP === 'MediFind-Pro') {
    const { value } = option;
    logEventGA4('filter', { type: 'generic_radius', action: 'select', item_id: value }, '7086f382-89d0-4e5e-9f29-91cd74ba0d9a');
  }
  setFilterValue(useGenericRadiusFilter, option);
};

export const clearGenericRadiusFilter = () =>
  useGenericRadiusFilter.setState({ ...initialState }, false, {
    type: 'clearGenericRadiusFilter',
  });

const getInitialStateByLocationFidelity = (fidelity) => {
  if (fidelity <= 3) return initialState;
  if (fidelity === 4) return createState({ value: 'state', fidelity: 4 });
  if (fidelity === 5) return createState({ value: 'national', fidelity: 5 });
  return createState(null);
};
