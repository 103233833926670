import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageFilterValue, sessionStorageMF } from '../../utils';
import { setFilterValue } from '../filterValue';

const initialState = {
  display: 'Days Of Operation',
  name: 'infusionCenterDaysOfOperation',
  type: 'multiSelect',
  selected: [],
  selectedVal: [],
  options: [
    { display: 'Sunday', value: 'sunday' },
    { display: 'Monday', value: 'monday' },
    { display: 'Tuesday', value: 'tuesday' },
    { display: 'Wednesday', value: 'wednesday' },
    { display: 'Thursday', value: 'thursday' },
    { display: 'Friday', value: 'friday' },
    { display: 'Saturday', value: 'saturday' },
    // { display: 'Weekdays', value: 'weekdays' },
    // { display: 'Weekends', value: 'weekends' },
  ],
};

const storeName = 'infusionCenterDaysOfOperation';
export const useInfusionCenterDaysOfOperationFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
        setFilterValue: (v) => updateInfusionCenterDaysOfOperationOption(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'infusionCenterDaysOfOperation' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const updateInfusionCenterDaysOfOperationOption = (option) => {
  setFilterValue(useInfusionCenterDaysOfOperationFilter, option);
};

export const clearInfusionCenterDaysOfOperationOption = () =>
  useInfusionCenterDaysOfOperationFilter.setState({ ...initialState }, false, {
    type: 'clearInfusionCenterDaysOfOperationOption',
  });
