import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageFilterValue, sessionStorageMF } from '../../utils';
import { setFilterValue } from '../filterValue';

const initialState = {
  display: 'Infusion Center Name',
  name: 'infusionCenterNameSubstring',
  type: 'input',
  selected: '',
};

const storeName = 'infusionCenterNameSubstring';
export const useInfusionCenterNameSubstringFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
        setFilterValue: (v) => updateInfusionCenterNameSubstringOption(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'infusionCenterNameSubstring' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const updateInfusionCenterNameSubstringOption = (option) => {
  setFilterValue(useInfusionCenterNameSubstringFilter, option);
};

export const clearInfusionCenterNameSubstringOption = () =>
  useInfusionCenterNameSubstringFilter.setState({ ...initialState }, false, {
    type: 'clearInfusionCenterNameSubstringOption',
  });
