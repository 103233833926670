import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageFilterValue, sessionStorageMF } from '../../utils';
import { setFilterValue } from '../filterValue';

const initialState = {
  display: 'Infusion Center',
  name: 'infusionCenterName',
  type: 'singleSelect',
  selected: [],
  selectedVal: [],
  options: [],
};

const storeName = 'infusionCenterName';
export const useInfusionCenterNameFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
        setFilterValue: (v) => updateInfusionCenterNameOption(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'infusionCenterName' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const updateInfusionCenterNameOption = (option) => {
  setFilterValue(useInfusionCenterNameFilter, option);
};

export const clearInfusionCenterNameOption = () =>
  useInfusionCenterNameFilter.setState({ ...initialState }, false, {
    type: 'clearInfusionCenterNameOption',
  });
