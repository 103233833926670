import { createContext, useContext } from 'react';
import { checkDependencies, useDependencies } from './dependencies';
import { getFilterValue } from './filterValue';

class Filters {
  dependencies = [];
  valueDependencies = [];
  #states = {};
  states = null;
  enabledStates = null;
  #values = {};
  values = null;
  enabledValues = null;
  filterList = [];
  filterDependencies = {};
  clearFunctions = [];
  filterOptions = {};
  enabledFilters = new Set();
  constructor({ filterDependencies = [], filters = [], clearFilters = [] } = {}) {
    const verifyHandler = {
      get(obj, prop, ...rest) {
        if (!obj.hasOwnProperty(prop) && ['LOCAL'].includes(process.env.NX_ENV)) {
          throw new Error(`Missing '${prop}' need for the filter lookup`);
        }
        return Reflect.get(obj, prop, ...rest);
      },
    };
    this.states = new Proxy(this.#states, verifyHandler);
    this.values = new Proxy(this.#values, verifyHandler);

    const _this = this;
    const enableHandler = {
      get(obj, prop, ...rest) {
        if (_this.enabledFilters.has(prop)) return Reflect.get(obj, prop, ...rest);
        else return undefined;
      },
      ownKeys(target) {
        return Reflect.ownKeys(target).filter((key) => _this.enabledFilters.has(key));
      },
    };
    this.enabledStates = new Proxy(this.#states, enableHandler);
    this.enabledValues = new Proxy(this.#values, enableHandler);

    for (const filterDependency of filterDependencies) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dep = useDependencies(filterDependency);

      this.filterDependencies[filterDependency.name] = dep;
      this.dependencies.push(dep.dependencies);
    }

    for (const useFilter of filters) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const state = useFilter();
      const value = getFilterValue(state);

      this.#values[state.name] = value;
      this.#states[state.name] = state;
      this.dependencies.push(value);
      this.valueDependencies.push(value);
      this.filterList.push(useFilter);
    }
    this.clearFunctions = clearFilters;
    this.enabledFilters = new Set(Object.keys(this.#states));
  }
  dependenciesLookup(name) {
    if (!name) {
      const keys = Object.keys(this.filterDependencies);
      if (keys.length === 1) return this.filterDependencies[keys[0]];
      else throw new Error("'name' not given and multiple dependencies exists");
    } else if (this.filterDependencies[name]) return this.filterDependencies[name];
    else throw new Error("'name' given, but does bot exist in the dependencies list");
  }
  checkFilterDependencies(dependencies) {
    return checkDependencies(this.dependenciesLookup().dependencies, dependencies);
  }
  checkFilterDependenciesByName(name, dependencies) {
    return checkDependencies(this.dependenciesLookup(name).dependencies, dependencies);
  }
  setFilterDependencies(dependencies, options) {
    this.dependenciesLookup().setDependencies(dependencies, options);
  }
  setFilterDependenciesByName(name, dependencies, options) {
    this.dependenciesLookup(name).setDependencies(dependencies, options);
  }
  clearFilters() {
    this.clearFunctions.forEach((func) => func());
  }
  getFilterOptions(name) {
    return this.filterOptions[name] || {};
  }
  useEnableFilters({ include = [], exclude = [] }) {
    exclude = exclude?.filter(Boolean) ?? [];
    include = include?.filter((x) => Boolean(x) && !exclude.includes(x)) ?? [];
    // Validate the keys
    include.forEach((key) => this.states[key]);
    exclude.forEach((key) => this.states[key]);
    if (include.length > 0) {
      this.enabledFilters = new Set(include);
    } else if (exclude.length > 0) {
      this.enabledFilters = new Set(Object.keys(this.#states));
      exclude.forEach((key) => this.enabledFilters.delete(key));
    } else {
      this.enabledFilters = new Set(Object.keys(this.#states));
    }
  }
  useFilterOptions(filters) {
    Object.keys(filters).forEach((name) => {
      // Validate the key
      this.states[name];

      this.filterOptions[name] = filters[name];
    });
  }
}

export const FilterContext = createContext(new Filters());

export const withFilters =
  (filters, filterDependencies = [], clearFilters = []) =>
  (WrappedComponent) => {
    const WithFilters = (props) => {
      const filterStates = new Filters({
        filters,
        filterDependencies,
        clearFilters,
      });

      return (
        <FilterContext.Provider value={filterStates}>
          <WrappedComponent {...props} />
        </FilterContext.Provider>
      );
    };

    WithFilters.displayName = `WithFilters(${WrappedComponent.displayName || WrappedComponent.name})`;
    return WithFilters;
  };

export const useFilterContext = () => {
  return useContext(FilterContext);
};
