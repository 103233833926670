import { Dependencies } from '../dependencies';
import { useGenericRadiusFilter } from '../location';
import { useInfusionCenterAmenitiesFilter } from './useAmenitiesFilter';
import { useInfusionCenterNameFilter } from './useCenterNameFilter';
import { useInfusionCenterNameSubstringFilter } from './useCenterNameSubstringFilter';
import { useInfusionCenterDaysOfOperationFilter } from './useDaysOfOperationFilter';
import { useInfusionCenterHoursOfOperationFilter } from './useHoursOfOperationFilter';
import { useInfusionCenterInsurancesFilter } from './useInsurancesFilter';
import { useInfusionCenterServicesFilter } from './useServicesFilter';
import { useInfusionCenterTypesFilter } from './useTypesFilter';

export const infusionCenterFiltersDependencies = new Dependencies('infusionCenterFiltersDependencies', [
  useGenericRadiusFilter,
  useInfusionCenterAmenitiesFilter,
  useInfusionCenterDaysOfOperationFilter,
  useInfusionCenterHoursOfOperationFilter,
  useInfusionCenterInsurancesFilter,
  useInfusionCenterTypesFilter,
  useInfusionCenterServicesFilter,
  useInfusionCenterNameFilter,
  useInfusionCenterNameSubstringFilter,
]);
export const clearInfusionCenterFilters = (...args) => {
  infusionCenterFiltersDependencies.clearDependencies(...args);
};
