import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageFilterValue, sessionStorageMF } from '../../utils';
import { setFilterValue } from '../filterValue';

const initialState = {
  display: 'Hours Of Operation',
  name: 'infusionCenterHoursOfOperation',
  type: 'multiSelect',
  selected: [],
  selectedVal: [],
  options: [
    { display: 'Morning (6 AM - 11 AM)', value: JSON.stringify({ open: '6:00 AM', close: '11:00 AM' }) },
    { display: 'Afternoon (11 AM - 5 PM)', value: JSON.stringify({ open: '11:00 AM', close: '5:00 PM' }) },
    { display: 'Evening (5 PM - Midnight)', value: JSON.stringify({ open: '5:00 PM', close: '11:59 PM' }) },
    { display: 'Night (Midnight - 6:00 AM)', value: JSON.stringify({ open: '12:01 AM', close: '6:00 AM' }) },
  ],
};

const storeName = 'infusionCenterHoursOfOperation';
export const useInfusionCenterHoursOfOperationFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
        setFilterValue: (v) => updateInfusionCenterHoursOfOperationOption(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'infusionCenterHoursOfOperation' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const updateInfusionCenterHoursOfOperationOption = (option) => {
  setFilterValue(useInfusionCenterHoursOfOperationFilter, option);
};

export const clearInfusionCenterHoursOfOperationOption = () =>
  useInfusionCenterHoursOfOperationFilter.setState({ ...initialState }, false, {
    type: 'clearInfusionCenterHoursOfOperationOption',
  });
