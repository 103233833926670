import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { isArrayEqual } from '@medifind/utils';

export const checkDependencies = (oldDependencies, newDependencies) => {
  return isArrayEqual(oldDependencies ?? [], newDependencies ?? []);
};
/**
 * Originally this used a registration function to register each filter to the Dependencies class.
 * This was nice because each filter could add itself when it was used.
 * However, this introduced an issue where if not all the filters were loaded it could result in a partial clear,
 * therefore the list of dependencies is required up front.
 */
class Dependencies {
  initialState = {
    dependencies: null,
  };
  dependencyList = [];
  name = null;
  constructor(storeName, dependencyList) {
    this.name = storeName;
    this.store = create(
      devtools(() => ({ ...this.initialState, setDependencies: (...args) => this.setDependencies(...args) }), {
        name: `${process.env.NX_APP}`,
        store: storeName,
      }),
    );
    this.dependencyList = dependencyList;
  }
  clearDependencies(options) {
    const { exclude = [], clearInitialState = false } = options || {};
    this.dependencyList.forEach((dep) => {
      const depName = dep.getState().name;
      if (!(exclude?.includes(depName) || exclude?.includes('all'))) {
        dep.getState().reset();
      }
    });

    if (clearInitialState)
      this.store.setState({ ...this.initialState }, false, {
        type: 'clearDependencies',
      });
  }
  setDependencies(dependencies, options) {
    const oldDependencies = this.store.getState().dependencies;
    if (!checkDependencies(oldDependencies, dependencies)) {
      // Don't clear if this is the first session
      if (oldDependencies !== null) {
        this.clearDependencies({ ...options, clearInitialState: true });
      }
      this.store.setState({ dependencies }, false, {
        type: 'setDependencies',
      });
    }
  }
  getStore() {
    return this.store;
  }
}
export const useDependencies = (filtersDependencies) => filtersDependencies.getStore()();
export { Dependencies as default, Dependencies };
