import { useEffect, useRef, useState } from 'react';
import { changeURLParams } from './url';
import { useRouter } from './useRouter';

// if pageFieldName is null then dont change the urls
export const usePageChanger = (pageFieldName, resetDependencies = [], pageChangeType = 'push') => {
  const router = useRouter();
  const { query } = router;
  const queryPage = query[pageFieldName] ? parseInt(query[pageFieldName]) : 1;
  const [page, setPage] = useState(queryPage || 1);
  const firstRun = useRef(true);

  useEffect(() => {
    if (pageFieldName && queryPage !== page) setPage(queryPage);
  }, [queryPage, page]);

  // useEffect(() => {
  //   // Note: This was for an edge case
  //   if (queryPage != page) setPage(queryPage);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [queryPage]);

  useEffect(() => {
    if (resetDependencies.length > 0 && page > 1 && !firstRun.current) {
      setPage(1);
      if (pageFieldName) changeURLParams(router, { [pageFieldName]: 1 }, { type: pageChangeType });
    }
    firstRun.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, resetDependencies);

  const changePageManualURLModification = (pageNum) => {
    if (queryPage !== page) {
      setPage(pageNum);
      return { [pageFieldName]: pageNum };
    } else return {};
  };

  const handlePageChange = (pageNum) => {
    if (pageFieldName) changeURLParams(router, changePageManualURLModification(pageNum), { type: pageChangeType });
    else setPage(pageNum);
  };

  return {
    page: pageFieldName ? queryPage : page,
    handlePageChange,
    changePageManualURLModification,
    resetPageManualURLModification: () => changePageManualURLModification(1),
    resetPage: () => handlePageChange(1),
  };
};
